import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ButtonSection } from '../../../../projects/WebApp/src/app/pages/customers-and-delivery/models/button-section';
import { HomeService } from './services/home.service';

@Component({
  selector: 'dh-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  public buttonSections$: BehaviorSubject<ButtonSection[]>;

  constructor(private readonly homeService: HomeService) {
    this.buttonSections$ = this.homeService.buttonSections$;
  }

}
