import { Injectable } from '@angular/core';
import {
  NavigationEnd,
  PRIMARY_OUTLET,
  Router,
  RouterEvent,
  UrlSegment,
  UrlSegmentGroup,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { URL_CONTROL_TOOL, URL_QM_PORTAL } from '../../../shared/constants/url-constants';
import { HasPermissionPipe } from '../../../shared/pipes/has-permission/has-permission.pipe';
import { Permission } from '../../auth/models/permission.enum';
import { HeaderMenuItem } from '../models/header-menu-item';

@Injectable({
  providedIn: 'root',
})
export class HeaderMenuService {
  public mainMenu: HeaderMenuItem[];
  public userMenu: HeaderMenuItem[];

  constructor(
    private router: Router,
    private hasPermission: HasPermissionPipe,
  ) {
  }

  public getSubMenu(): Observable<Promise<HeaderMenuItem[]>> {
    return this.router.events.pipe(
      filter((e: RouterEvent) => e instanceof NavigationEnd),
      map((event: NavigationEnd) => {
        const tree: UrlTree = this.router.parseUrl(event.url);
        const segmentGroup: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        if (segmentGroup) {
          const segments: UrlSegment[] = segmentGroup.segments;
          return segments[0].path;
        }
        return '';
      }),
      map((path: string) => this.mainMenu.find((item: HeaderMenuItem) => item.route === path)),
      map((item: HeaderMenuItem) => item && item.subItems ? item.subItems : []),
      map((subMenu: HeaderMenuItem[]) => this.checkPermissions(subMenu)),
    );
  }

  public getSubMenuItemsAtStart(): Promise<HeaderMenuItem[]> {
    // remove first '/'
    let url = this.router.url.slice(1, this.router.url.length);
    // look if there is another /
    const index = url.indexOf('/');
    if (index > -1) {
      url = url.slice(0, index);
    }

    const menuItem = this.mainMenu.find((item: HeaderMenuItem) => item.route === url);
    if (menuItem && menuItem.subItems) {
      return this.checkPermissions(menuItem.subItems);
    }

    return Promise.resolve([]);
  }

  public async getMainMenu(): Promise<HeaderMenuItem[]> {
    this.mainMenu = [
      {
        label: 'menu-customers-delivery.value',
        route: 'customers-and-delivery',
        permission: Permission.DH_CUSTOMER_DELIVERY,
        subItems: this.getHeaderMenuSubItems(),
      },
      {
        label: 'menu-system.value',
        route: 'system',
      },
      {
        label: 'menu-live.value',
        route: 'monitoring',
        permission: Permission.DH_LIVE,
      },
      {
        label: 'menu-qm.value',
        link: URL_QM_PORTAL,
        permission: Permission.DH_QM,
      },
      {
        label: 'menu-control.value',
        link: URL_CONTROL_TOOL,
        permission: Permission.DH_CONTROL,
      },
    ];

    return await this.checkPermissions(this.mainMenu);
  }

  public getHeaderMenuSubItems(): HeaderMenuItem[] {
    const headerMenuItems: HeaderMenuItem[] = [];

    headerMenuItems.push({
                           label: 'menu-customers-delivery-customers.value',
                           route: 'customers-and-delivery/customers',
                           permission: Permission.DH_CUSTOMER_DELIVERY_CUSTOMERS,
                         },
                         {
                           label: 'menu-customers-delivery-projects.value',
                           route: 'customers-and-delivery/projects',
                           permission: Permission.DH_CUSTOMER_DELIVERY_PROJECTS,
                         },
                         {
                           label: 'menu-customers-delivery-feeds.value',
                           route: 'customers-and-delivery/feeds',
                           permission: Permission.DH_CUSTOMER_DELIVERY_FEEDS,
                         },
                         {
                           label: 'menu-customers-delivery-orders.value',
                           route: 'customers-and-delivery/delivery-orders',
                           permission: Permission.DH_CUSTOMER_DELIVERY_DELIVERY_TASKS,
                         });

    if (environment.hasUserGroup) {
      headerMenuItems.push({
                             label: 'menu-customers-delivery-groups.value',
                             route: 'customers-and-delivery/groups',
                             permission: Permission.DH_CUSTOMER_DELIVERY_GROUPS,
                           });
    }
    headerMenuItems.push({
                           label: 'menu-customers-delivery-contacts.value',
                           route: 'customers-and-delivery/contact-partners',
                           permission: Permission.DH_CUSTOMER_DELIVERY_CONTACTS,
                         });

    return headerMenuItems;
  }

  public async getUserMenu(): Promise<HeaderMenuItem[]> {
    this.userMenu = [
      {
        label: 'menu-user-admin.value',
        link: environment.userManagementUrl,
        permission: Permission.DH_ADMIN,
      },
      {
        label: 'menu-user-logout.value',
        route: 'logout',
      },
    ];

    return await this.checkPermissions(this.userMenu);
  }

  private async checkPermissions(menu: HeaderMenuItem[]): Promise<HeaderMenuItem[]> {
    const permissions: boolean[] = await Promise.all(
      menu.map((menuItem: HeaderMenuItem) => {
        return this.hasPermission.transform(menuItem.permission).pipe(take(1)).toPromise();
      }),
    );

    return menu.filter((value, index) => permissions[index]);
  }
}
