<a *ngIf="url; else button" [href]="url" target="_blank">
  <ng-container *ngTemplateOutlet="button"></ng-container>
</a>

<ng-template #button>
  <div class="icon-button"
       [class]="style"
       fxLayout="column"
       fxLayoutAlign="center center"
       fxLayoutGap="10px">
    <div class="icon-button-background-circle" fxLayout="row" fxLayoutAlign="center center">
      <fa-icon *ngIf="icon" [icon]="icon" [size]="'2x'" [styles]="iconStyles"></fa-icon>
      <ng-container *ngFor="let iconLayer of iconLayers">
        <fa-icon *ngIf="iconLayer?.type === iconLayerType.ICON"
                 [icon]="iconLayer?.icon"
                 [size]="iconLayer?.size"
                 [transform]="iconLayer?.transform"
                 [styles]="iconLayer?.style"></fa-icon>
        <fa-layers *ngIf="iconLayer?.type === iconLayerType.TEXT" [fixedWidth]="true">
          <fa-layers-text
            [content]="iconLayer?.content"
            [transform]="iconLayer?.transform"
            [size]="iconLayer?.size"
            [ngStyle]="getIconStyle(iconLayer?.style)"></fa-layers-text>
        </fa-layers>
      </ng-container>
    </div>
    <div class="icon-button-label">{{ label }}</div>
  </div>
</ng-template>
