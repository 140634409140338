<div class="refresh-button-wrapper" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">

  <button class="refresh-button" mat-icon-button fxLayout="row" fxLayoutAlign="center center"
          [disabled]="disableButton" (click)="debounceRefreshClick()">
    <fa-icon icon="redo-alt"></fa-icon>
  </button>
  <span class="timer">
    {{('last-update-ago.value' | translate)}}
    <span class="hours" *ngIf="passedHours > 0">
      {{passedHours + ' ' + ('hours.shortValue' | translate) + ' '}}
    </span>
    <span class="min-sec">
      <span class="minutes" *ngIf="passedMinutes > 0 || passedHours > 0">
        {{passedMinutes + ' ' + ('minutes.shortValue' | translate) + ' '}}
      </span>
      {{passedSeconds + ' ' + ('seconds.shortValue' | translate)}}
    </span>
  </span>
</div>
