import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslationTextModule } from '../../../component/translation-text/translation-text.module';
import { SharedLayoutModule } from '../../../../../../projects/WebApp/src/app/shared/shared-layout/shared-layout.module';
import { ActionButtonModule } from '../../../../../../projects/WebApp/src/app/shared/shared-layout/custom-ui-elements/action-button/action-button.module';
import { DialogComponent } from './dialog.component';

@NgModule({
    declarations: [
        DialogComponent,
    ],
    imports: [
        CommonModule,
        SharedLayoutModule,
        ActionButtonModule,
        RouterModule,
        TranslationTextModule,
    ],
    exports: [
        DialogComponent,
    ]
})
export class DialogModule { }
