import { Component, Input } from '@angular/core';

@Component({
  selector: 'dh-sub-headline',
  templateUrl: './sub-headline.component.html',
  styleUrls: ['./sub-headline.component.scss'],
})
export class SubHeadlineComponent {
  @Input() text: string;
}
