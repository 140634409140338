import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from './components/table/table.module';
import { TranslationTextModule } from '../../../../../src/app/shared/component/translation-text/translation-text.module';
import { HasPermissionModule } from '../../../../../src/app/shared/pipes/has-permission/has-permission.module';
import { IncludesModule } from './pipes/includes/includes.module';
import { MapToKeyModule } from './pipes/map-to-key/map-to-key.module';
import { ActionButtonModule } from './shared-layout/custom-ui-elements/action-button/action-button.module';
import { ApiErrorsModule } from './shared-layout/custom-ui-elements/api-errors/api-errors.module';
import { DialogModule } from '../../../../../src/app/shared/shared-layout/custom-ui-elements/dialog/dialog.module';
import { FormCheckboxModule } from './shared-layout/custom-ui-elements/form-checkbox/form-checkbox.module';
import { FormDatepickerModule } from './shared-layout/custom-ui-elements/form-datepicker/form-datepicker.module';
import { FormErrorsModule } from './shared-layout/custom-ui-elements/form-errors/form-errors.module';
import { FormInputModule } from './shared-layout/custom-ui-elements/form-input/form-input.module';
import { FormRadiobuttonsModule } from './shared-layout/custom-ui-elements/form-radiobuttons/form-radiobuttons.module';
import { FormSelectSearchModule } from './shared-layout/custom-ui-elements/form-select-search/form-select-search.module';
import { FormTextareaModule } from './shared-layout/custom-ui-elements/form-textarea/form-textarea.module';
import { HeadlineModule } from './shared-layout/custom-ui-elements/headline/headline.module';
import { SubHeadlineModule } from './shared-layout/custom-ui-elements/sub-headline/sub-headline.module';
import { SharedLayoutModule } from './shared-layout/shared-layout.module';
import { GetFormGroupFromFormArrayModule } from './pipes/get-form-group-from-form-array/get-form-group-from-form-array.module';
import { DebouncePipe } from './pipes/debounce/debounce.pipe';
import { MainSharedModule } from '../../../../../src/app/shared/main-shared.module';
import { RefreshButtonComponent } from './components/refresh-button/refresh-button.component';

@NgModule({
  declarations: [DebouncePipe, RefreshButtonComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    SharedLayoutModule,
    TableModule,
    DialogModule,
    HasPermissionModule,
    ApiErrorsModule,
    TranslationTextModule,
    IncludesModule,
    MapToKeyModule,
    GetFormGroupFromFormArrayModule,
    MainSharedModule,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ActionButtonModule,
    FormCheckboxModule,
    FormRadiobuttonsModule,
    FormDatepickerModule,
    FormInputModule,
    FormTextareaModule,
    FormSelectSearchModule,
    HeadlineModule,
    SubHeadlineModule,
    SharedLayoutModule,
    TableModule,
    DialogModule,
    TranslateModule,
    FormErrorsModule,
    HasPermissionModule,
    ApiErrorsModule,
    TranslationTextModule,
    IncludesModule,
    MapToKeyModule,
    GetFormGroupFromFormArrayModule,
    DebouncePipe,
    MainSharedModule,
    RefreshButtonComponent,
  ],
})
export class SharedModule {
}
