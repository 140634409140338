<div class="select-div" fxLayoutAlign="center center">
  <select matNativeControl
          #customselect
          class="form-select"
          matTooltip="{{ label }}"
          matTooltipPosition="above"
          (click)="opened = !opened"
          (focusout)="opened = false"
          [class.error]="control?.invalid && control?.touched"
          [(ngModel)]="value"
          (ngModelChange)="changeValue($event); selectionChange.emit($event)"
          [disabled]="isDisabled">
    <option [label]="label" *ngIf="label" [ngValue]="undefined" selected disabled hidden>{{ label }}</option>
    <option [label]="label" *ngIf="label" value="" selected disabled hidden>{{ label }}</option>
    <option [label]="label"
            *ngIf="label"
            [ngValue]="null"
            selected
            [disabled]="!showDefault"
            [hidden]="!showDefault">{{ label }}</option>
    <option *ngFor="let item of selections" [ngValue]="item.value">{{item.viewValue}}</option>
  </select>
  <mat-spinner class="spinner" *ngIf="!selections" [diameter]="20"></mat-spinner>
  <fa-icon class="custom-icon" *ngIf="!opened && selections" icon="caret-down"></fa-icon>
  <fa-icon class="custom-icon" *ngIf="opened && selections" icon="caret-up"></fa-icon>
</div>
