import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { SharedLayoutModule } from '../../shared-layout.module';
import { FormSelectSearchComponent } from './form-select-search.component';
import { GetByValuePipe } from './pipes/get-by-value.pipe';

@NgModule({
  declarations: [
    FormSelectSearchComponent,
    GetByValuePipe,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedLayoutModule,
    SelectDropDownModule,
  ],
  exports: [
    FormSelectSearchComponent,
  ],
})
export class FormSelectSearchModule {
}
