import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedLayoutModule } from '../../shared-layout.module';
import { ApiErrorsComponent } from './api-errors.component';

@NgModule({
  declarations: [ApiErrorsComponent],
  imports: [
    CommonModule,
    SharedLayoutModule,
    TranslateModule,
  ],
  exports: [ApiErrorsComponent],
})
export class ApiErrorsModule { }
