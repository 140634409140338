<div class="table-filter-container"
     fxLayout="row wrap-reverse"
     fxLayoutAlign="space-between start"
     fxLayoutGap="20px">

  <div class="table-filter-input-container"
       *ngIf="tableConfig?.filterTextPlaceholder"
       [fxFlex]="'500px'"
       [fxFlex.lt-lg]="'auto'">
    <dh-form-input [(ngModel)]="filterText"
                   (ngModelChange)="filterChanged.emit()"
                   [title]="tableConfig?.filterTextPlaceholder | translate"
                   [placeholder]="tableConfig?.filterTextPlaceholder | translate">
    </dh-form-input>
  </div>

  <template *ngTemplateOutlet="headerButtons;"></template>

  <div *ngIf="tableConfig?.filterSelects?.length > 0" class="table-select-section-container" fxLayout="row wrap" fxLayoutGap="20px">
    <dh-table-select-section *ngFor="let filterSelect of tableConfig?.filterSelects"
                             [(ngModel)]="filterSelect.value"
                             (ngModelChange)="filterChanged.emit()"
                             [title]="filterSelect?.label | translate"
                             [options]="filterSelect?.options">
    </dh-table-select-section>
  </div>

</div>

<div class="table-container" fxLayout="column" fxLayoutAlign="space-between">
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>

  <div class="table-container-main" fxLayout="column" fxLayoutAlign="center stretch">

    <table mat-table multiTemplateDataRows [dataSource]="dataSource" class="table" matSort>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select" *ngIf="isSelectable">
        <th mat-header-cell *matHeaderCellDef>
          <dh-form-checkbox (click)="$event.stopPropagation()"
                            (ngModelChange)="masterToggle()"
                            [ngModel]="selection.hasValue() && isAllSelected()">
          </dh-form-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <dh-form-checkbox (click)="$event.stopPropagation()"
                            (ngModelChange)="selection.toggle(row?.id); selectionChange.next(selection);"
                            [ngModel]="selection.isSelected(row?.id)">
          </dh-form-checkbox>
        </td>
      </ng-container>

      <!-- define columns -->
      <ng-container *ngFor="let column of tableConfig?.columns" [matColumnDef]="column.name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ column.label | translate }}
        </th>
        <td mat-cell *matCellDef="let row" [ngClass]="column?.styleClass">
          <ng-container *ngIf="!column.templateRef">
            {{ row[column.name] }}
          </ng-container>
          <ng-container *ngIf="column.templateRef">
            <template *ngTemplateOutlet="column.templateRef;context:{item: row}"></template>
          </ng-container>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="detail-row">
          <div class="element-detail"
               *ngIf="expandedElements.isSelected(element)"
               [@detailExpand]>
              <div fxFlex="100%">
                <template *ngTemplateOutlet="expandedDetailRow; context:{item: element}"></template>
              </div>
          </div>
        </td>
      </ng-container>

      <!-- define action buttons -->
      <ng-container [matColumnDef]="'action-buttons'">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="align-right">

          <mat-spinner *ngIf="row?.isLoading" [diameter]="30"></mat-spinner>
          <ng-container *ngIf="!row?.isLoading">

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <div>
                <dh-table-action-button (click)="triggerAction(tableConfigActionButton.EDIT, row)"
                                        *ngIf="(tableConfig?.actionButtons | hasActionButton: tableConfigActionButton.EDIT)"
                                        [icon]="'pencil-alt'">
                </dh-table-action-button>
                <dh-table-action-button (click)="triggerAction(tableConfigActionButton.ACTIVE, row)"
                                        *ngIf="(tableConfig?.actionButtons | hasActionButton: tableConfigActionButton.ACTIVE) && row?.isActive && !row?.isDeleted"
                                        [icon]="'minus'">
                </dh-table-action-button>
                <dh-table-action-button (click)="triggerAction(tableConfigActionButton.ACTIVE, row)"
                                        *ngIf="(tableConfig?.actionButtons | hasActionButton: tableConfigActionButton.ACTIVE) && (!row?.isActive || row?.isDeleted)"
                                        [icon]="'reply'">
                </dh-table-action-button>
                <dh-table-action-button (click)="triggerAction(tableConfigActionButton.DELETE, row)"
                                        *ngIf="(tableConfig?.actionButtons | hasActionButton: tableConfigActionButton.DELETE) && !row?.isDeleted"
                                        [icon]="'trash'">
                </dh-table-action-button>
                <dh-table-action-button (click)="triggerAction(tableConfigActionButton.DELETE, row)"
                                        *ngIf="(tableConfig?.actionButtons | hasActionButton: tableConfigActionButton.DELETE) && row?.isDeleted"
                                        [icon]="'times'">
                </dh-table-action-button>
                <!-- add action buttons from template -->
                <template *ngTemplateOutlet="additionalActionButtons; context:{item: row}"></template>
              </div>

              <div *ngIf="isExpandable">
                <fa-icon icon="caret-down" size="lg" *ngIf="!expandedElements.isSelected(row)"></fa-icon>
                <fa-icon icon="caret-up" size="lg" *ngIf="expandedElements.isSelected(row)"></fa-icon>
              </div>

            </div>

          </ng-container>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns; let i = dataIndex"
          class="element-row"
          [ngClass]="{
            'expandable-row': isExpandable,
            'expanded-row': expandedElements.isSelected(element),
            'odd': i%2 === 0, 'even': i%2 === 1
          }"
          (click)="expandedElements.toggle(element)">{{i}}
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']; let i = dataIndex"
          class="detail-row"
          [ngClass]="{ 'odd': i%2 === 0, 'even': i%2 === 1 }">
      </tr>

    </table>
  </div>
  <dh-table-paginator #paginator
                      [length]="count"
                      [hidden]="hidePagination"
                      (pageSizeChange)="setPageSize($event)">
  </dh-table-paginator>

</div>
