<mat-toolbar fxLayout="row" fxLayoutAlign="space-between stretch" class="header-toolbar" fxShow="true" fxShow.lt-lg="false">
  <dh-header-logo routerLink="/"></dh-header-logo>
  <div fxLayout="column" fxLayoutAlign="space-between stretch">
    <dh-header-main-menu></dh-header-main-menu>
    <dh-header-sub-menu></dh-header-sub-menu>
  </div>
  <dh-header-user-menu></dh-header-user-menu>
</mat-toolbar>

<dh-mobile-menu fxShow="false" fxShow.lt-lg="true"></dh-mobile-menu>
