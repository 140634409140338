import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { FormSelect } from '../../../../../../../../../src/app/shared/shared-layout/custom-ui-elements/form-select/model/form-select';
import { ActionButtonType } from '../../../../shared-layout/custom-ui-elements/action-button/enum/action-button-type.enum';

@Component({
  selector: 'dh-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss'],
})
export class TablePaginatorComponent {

  @Input() public length: number;
  @Input() public pageSize: number;
  @Input() public pageSizeOptions: FormSelect[];
  @Input() public visiblePages: number;
  @Input() public showAllButton: boolean;
  @Output() public pageSizeChange: EventEmitter<number>;
  public get pageIndex(): number {
    return this.page.getValue();
  }
  public page: BehaviorSubject<number>;
  public totalPages: number;
  public actionButton = ActionButtonType;
  public selections: FormSelect[];

  constructor(private translateService: TranslateService) {
    this.length = 0;
    this.pageSize = 20;
    this.translateService.get([
      'all-entries.value',
      'entries-per-page.value',
    ]).subscribe((translations: any) => {
      this.pageSizeOptions = [
        {
          value: 0,
          viewValue: translations['all-entries.value'],
        },
        {
          value: 20,
          viewValue: `20 ${translations['entries-per-page.value']}`,
        },
        {
          value: 50,
          viewValue: `50 ${translations['entries-per-page.value']}`,
        },
        {
          value: 100,
          viewValue: `100 ${translations['entries-per-page.value']}`,
        },
      ];
    });
    this.visiblePages = 5;
    this.showAllButton = false;
    this.page = new BehaviorSubject(1);
    this.totalPages = 1;
    this.pageSizeChange = new EventEmitter();
  }

  public pages(page: number): any[] {
    return this.getPager(this.length, page);
  }

  public getPager(totalItems: number, currentPage: number): number[] {
    // calculate total pages
    this.totalPages = Math.ceil(totalItems / this.pageSize);
    let sCurrentPage: number = currentPage;

    // ensure current page isn't out of range
    if (sCurrentPage < 1) {
      sCurrentPage = 1;
    } else if (sCurrentPage > this.totalPages) {
      sCurrentPage = this.totalPages;
    }

    let startPage: number;
    let endPage: number;
    if (this.totalPages <= this.visiblePages) {
      // less than visiblePages total pages so show all
      startPage = 1;
      endPage = this.totalPages;
    } else {
      const ceilPages: number = Math.ceil(this.visiblePages / 2);
      const floorPages: number = Math.floor(this.visiblePages / 2);
      // more than visiblePages total pages so calculate start and end pages
      if (currentPage <= ceilPages) {
        startPage = 1;
        endPage = this.visiblePages;
      } else if (currentPage + floorPages >= this.totalPages) {
        startPage = this.totalPages - (this.visiblePages - 1);
        endPage = this.totalPages;
      } else {
        startPage = currentPage - floorPages;
        endPage = currentPage + floorPages;
      }
    }

    // create an array of pages to ng-repeat in the pager control
    // return object with all pager properties required by the view
    return Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
  }

  public showAll(): void {
    // show all items
    this.setPageSize(0);
  }

  public setPageSize(pageSize: number | string): void {
    const cPageSize = Number(pageSize);
    this.pageSize = cPageSize;
    this.pageSizeChange.emit(cPageSize);
    this.page.next(cPageSize ? 1 : 0);
  }

  public setPage(page: number): void {
    if (page > 0 && page <= this.totalPages) {
      this.page.next(page);
    }
  }

}
