import { Component, Input } from '@angular/core';
import { IconLayer, IconLayerType } from './models/icon-layer';

@Component({
  selector: 'dh-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
  @Input() public icon: string;
  @Input() public iconStyles: any = {
    color: '#374b55',
  };
  @Input() public label: string;
  @Input() public style: 'round' | 'rectangle';
  @Input() public url: string;

  @Input()
  public set iconLayers(iconLayers: IconLayer[]) {
    this._iconLayers = (iconLayers || []).map((iconLayer: IconLayer) => {
      return {
        ...iconLayer,
        style: {
          color: '#374b55',
          ...(iconLayer.style || {}),
        },
      };
    });
  }

  public get iconLayers(): IconLayer[] {
    return this._iconLayers;
  }

  private _iconLayers: IconLayer[];
  public iconLayerType = IconLayerType;

  constructor() {
    this.style = 'round';
  }

  public getIconStyle(iconStyles: any): any {
    return {
      ...this.iconStyles,
      ...(iconStyles || {}),
    };
  }
}
