import { NgModule } from '@angular/core';
import { SharedLayoutModule } from '../../shared-layout.module';
import { ActionButtonComponent } from './action-button.component';

@NgModule({
  declarations: [ActionButtonComponent],
  imports: [SharedLayoutModule],
  exports: [ActionButtonComponent],
})
export class ActionButtonModule {
}
