import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HasPermissionModule } from '../../shared/pipes/has-permission/has-permission.module';
import { IconButtonModule } from '../../shared/shared-layout/custom-ui-elements/icon-button/icon-button.module';
import { SectionModule } from '../../shared/shared-layout/custom-ui-elements/section/section.module';
import { SharedLayoutModule } from '../../shared/shared-layout/shared-layout.module';
import { HomeComponent } from './home.component';
import { HomeService } from './services/home.service';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    SharedLayoutModule,
    SectionModule,
    HasPermissionModule,
    IconButtonModule,
    RouterModule,
  ],
  providers: [
    HomeService,
  ],
})
export class HomeModule {
}
