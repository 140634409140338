import { Component } from '@angular/core';
import { StsAuthService } from 'sts-auth';

@Component({
  selector: 'dh-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  constructor(private authService: StsAuthService) {
  }

  public handleAction(action: string): void {
    switch (action) {
      case 'login-action':
        this.login();
        break;
      default:
        return;
    }
    return;
  }

  public login(): void {
    this.authService.login();
  }
}
