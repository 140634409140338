import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes',
})
export class IncludesPipe implements PipeTransform {

  public transform(value: string | string[], search: string): boolean {
    const _search = search || '';
    let _value;
    if (Array.isArray(value)) {
      _value = value.join().toLowerCase();
    } else {
      _value = (value || '').toLowerCase();
    }
    return _value.includes(_search.toLowerCase());
  }

}
