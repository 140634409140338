import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { Subject, timer } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

const SECONDS_TIMER_INTERVAL_MS: number = 1000;

/**
 * @description
 * This component raises an event when the refresh button is triggered;
 * -triggering refresh resets the timer;
 *
 * @param resetTimer can be used to manually reset the button;
 * @param disableButton can be used to disable the button;
 * @param handleRefresh emits the click event;
 * @example
 * <live-refresh-button *ngIf="{boolean}" [disableButton]="{boolean}" [resetTimer]="{boolean}"
 (handleRefresh)="handleRefresh()">
 </live-refresh-button>
 */
@Component({
  selector: 'dh-refresh-button',
  templateUrl: './refresh-button.component.html',
  styleUrls: ['./refresh-button.component.scss'],
})
export class RefreshButtonComponent implements OnInit {
  @Input() public resetTimer: boolean;
  @Input() public disableButton: boolean;

  @Output() public handleRefresh: EventEmitter<void>;

  public passedSeconds: number;
  public passedMinutes: number;
  public passedHours: number;

  private clickRefresh: Subject<void>;
  private onDestroy$: Subject<void>;
  private onResetTimer$: Subject<void>;

  constructor() {
    this.handleRefresh = new EventEmitter<void>();
    this.clickRefresh = new Subject();
    this.onDestroy$ = new Subject();
    this.onResetTimer$ = new Subject();
    this.passedSeconds = 0;
    this.passedMinutes = 0;
    this.passedHours = 0;
  }

  ngOnInit(): void {
    this.startTimerInterval();

    this.clickRefresh.pipe(
      debounceTime(500),
      takeUntil(this.onDestroy$),
    ).subscribe(() => {
      this.startTimerInterval();
      this.handleRefresh.emit();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.resetTimer?.currentValue !== changes.resetTimer?.previousValue) {
      this.startTimerInterval();
    }

    if (changes && changes.disableButton?.currentValue !== changes.disableButton?.previousValue) {
      if (changes.disableButton?.currentValue) {
        this.stopTimerInterval();
      } else {
        this.startTimerInterval();
      }
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onResetTimer$.next();
  }

  public debounceRefreshClick(): void {
    this.clickRefresh.next();
  }

  private startTimerInterval(): void {
    this.onResetTimer$.next();
    timer(0, 1000)
      .pipe(takeUntil(this.onResetTimer$))
      .subscribe((val: number): void => {
        const timePassed: number = val * SECONDS_TIMER_INTERVAL_MS;
        this.passedSeconds = moment.duration(timePassed).seconds();
        this.passedMinutes = moment.duration(timePassed).minutes();
        this.passedHours = parseInt(moment.duration(timePassed).as('hours').toPrecision().toString().split('.')[0], 10);
      });
  }

  private stopTimerInterval(): void {
    this.onResetTimer$.next();
    this.passedSeconds = 0;
    this.passedMinutes = 0;
    this.passedHours = 0;
  }

}
