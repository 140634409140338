import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HasPermissionPipe } from '../../shared/pipes/has-permission/has-permission.pipe';
import { MainSharedModule } from '../../shared/main-shared.module';
import { HeaderComponent } from './header.component';
import { HeaderButtonComponent } from './partials/header-button/header-button.component';
import { HeaderLogoComponent } from './partials/header-logo/header-logo.component';
import { HeaderMainMenuComponent } from './partials/header-main-menu/header-main-menu.component';
import { HeaderSubItemComponent } from './partials/header-sub-item/header-sub-item.component';
import { HeaderSubMenuComponent } from './partials/header-sub-menu/header-sub-menu.component';
import { HeaderUserMenuComponent } from './partials/header-user-menu/header-user-menu.component';
import { MobileMenuComponent } from './partials/mobile-menu/mobile-menu.component';

@NgModule({
  imports: [
    MainSharedModule,
    RouterModule,
  ],
  declarations: [
    HeaderComponent,
    MobileMenuComponent,
    HeaderLogoComponent,
    HeaderMainMenuComponent,
    HeaderSubMenuComponent,
    HeaderUserMenuComponent,
    HeaderButtonComponent,
    HeaderSubItemComponent,
  ],
  providers: [
    HasPermissionPipe,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {
}
