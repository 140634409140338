import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Pipe({
  name: 'debounce',
})
export class DebouncePipe implements PipeTransform {

  transform(observable: Observable<any>, time: number): Observable<any> {
    if (observable) {
      return observable.pipe(
        debounceTime(time),
        distinctUntilChanged(),
      );
    }

  }
}
