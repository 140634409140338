import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from './models/dialog-data';

@Component({
  selector: 'dh-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {

  public get data(): DialogData {
    return this._data;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: DialogData,
    private dialogRef: MatDialogRef<DialogComponent>,
  ) {}

  public close(): void {
    this.dialogRef.close();
  }

}
