<dh-section [title]="title" layoutGap="0px">
  <div class="select-button-container" fxLayout="row wrap" fxLayoutGap="5px">
    <button
      mat-stroked-button
      *ngFor="let option of options"
      (click)="select(option)"
      [class.active]="selected(option)">
      {{ option?.label | translate | uppercase }}
    </button>
  </div>
</dh-section>
