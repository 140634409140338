import { NgModule } from '@angular/core';
import { SharedLayoutModule } from '../../shared-layout.module';
import { FormSelectComponent } from './form-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [FormSelectComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SharedLayoutModule,
  ],
  exports: [FormSelectComponent],
})
export class FormSelectModule {
}
