import { Component, forwardRef, Input, Optional, ViewChild } from '@angular/core';
import { ControlContainer, FormControlDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CanDisable } from '@angular/material/core';
import { BaseFormComponent } from '../../../../../../../../src/app/shared/shared-layout/custom-ui-elements/base-form/base-form.component';
import { BaseFormElement } from '../../../../../../../../src/app/shared/shared-layout/custom-ui-elements/base-form/models/base-form-element';

@Component({
  selector: 'dh-form-textarea',
  templateUrl: './form-textarea.component.html',
  styleUrls: ['./form-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormTextareaComponent),
      multi: true,
    },
  ],
})
export class FormTextareaComponent extends BaseFormComponent {

  @ViewChild(FormControlDirective, { static: true })
  public formControlDirective: FormControlDirective;

  @Input() public placeholder: string;
  @Input() public rows: number = 10;
  @Input() public errorMsg: string;

  constructor(@Optional() protected controlContainer: ControlContainer) {
    super(controlContainer);
  }

  protected getFormElementValueAccessor(): BaseFormElement {
    return this.formControlDirective.valueAccessor;
  }

  protected getFormElement(): CanDisable {
    return this.formControlDirective;
  }

}
