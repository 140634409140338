import { Component, Input } from '@angular/core';
import { HeaderMenuItem } from '../../models/header-menu-item';

@Component({
  selector: 'dh-header-button',
  templateUrl: './header-button.component.html',
  styleUrls: ['./header-button.component.scss'],
})
export class HeaderButtonComponent {
  @Input() public item: HeaderMenuItem;
}
