import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ButtonSection } from '../../../../../projects/WebApp/src/app/pages/customers-and-delivery/models/button-section';
import { Permission } from '../../../core/auth/models/permission.enum';
import { URL_CONTROL_TOOL, URL_QM_PORTAL } from '../../../shared/constants/url-constants';
import { IconLayerType } from '../../../shared/shared-layout/custom-ui-elements/icon-button/models/icon-layer';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  public buttonSections$: BehaviorSubject<ButtonSection[]>;

  constructor(private readonly translateService: TranslateService) {
    this.buttonSections$ = new BehaviorSubject([]);
    this.translateService.get([
      'sections',
      'menu-customers-delivery',
      'menu-system',
      'menu-live',
      'menu-qm',
      'menu-control',
    ]).subscribe((translations: any) => {
      this.buttonSections$.next([
        {
          title: translations['sections'].value,
          permission: Permission.DH_CUSTOMER_DELIVERY_CUSTOMERS,
          iconButtons: [
            {
              label: translations['menu-customers-delivery'].value.toUpperCase(),
              route: '/customers-and-delivery',
              permission: Permission.DH_CUSTOMER_DELIVERY,
              iconLayers: [
                {
                  type: IconLayerType.ICON,
                  icon: 'user',
                  size: '3x',
                  transform: 'left-2',
                },
                {
                  type: IconLayerType.ICON,
                  icon: 'upload',
                  size: '3x',
                  transform: 'right-2',
                },
              ],
            },
            {
              label: translations['menu-system'].value.toUpperCase(),
              route: '/system',
              permission: Permission.DH_SYSTEM,
              iconLayers: [
                {
                  type: IconLayerType.ICON,
                  icon: 'network-wired',
                  size: '3x',
                },
              ],
            },
            {
              label: translations['menu-live'].value.toUpperCase(),
              route: '/monitoring',
              permission: Permission.DH_LIVE,
              iconLayers: [
                {
                  type: IconLayerType.TEXT,
                  content: 'LIVE',
                  style: {
                    fontWeight: 'bold',
                    fontSize: '28px',
                  },
                },
              ],
            },
            {
              label: translations['menu-qm'].value.toUpperCase(),
              permission: Permission.DH_QM,
              url: URL_QM_PORTAL,
              iconLayers: [
                {
                  type: IconLayerType.TEXT,
                  content: 'QM',
                  style: {
                    fontWeight: 'bold',
                    fontSize: '28px',
                  },
                },
              ],
            },
            {
              label: translations['menu-control'].value.toUpperCase(),
              permission: Permission.DH_CONTROL,
              url: URL_CONTROL_TOOL,
              iconLayers: [
                {
                  icon: 'traffic-light',
                  type: IconLayerType.ICON,
                  size: '3x',
                },
              ],
            },
          ],
        },
      ]);
    });
  }
}
