import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormRadiobuttonsComponent } from './form-radiobuttons.component';
import { SharedLayoutModule } from '../../shared-layout.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
  declarations: [FormRadiobuttonsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedLayoutModule,
  ],
  exports: [FormRadiobuttonsComponent],
})
export class FormRadiobuttonsModule { }
