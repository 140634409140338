import { Component } from '@angular/core';
import versionJson from '../../../../../../version.json';

@Component({
  selector: 'dh-header-logo',
  templateUrl: './header-logo.component.html',
  styleUrls: ['./header-logo.component.scss'],
})
export class HeaderLogoComponent {
  public buildDate: string = versionJson.buildDate;
  public hash: string = versionJson.hash;
}
