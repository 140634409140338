import { Pipe, PipeTransform } from '@angular/core';
import { TableConfigActionButton } from '../models/table-config';

@Pipe({
  name: 'hasActionButton',
})
export class HasActionButtonPipe implements PipeTransform {

  transform(actionButtons: TableConfigActionButton[], actionButton: TableConfigActionButton): boolean {
    return actionButtons.includes(actionButton);
  }

}
