import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { StsAuthService } from 'sts-auth';

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {

  private accessToken: string;

  constructor(private authService: StsAuthService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.accessToken = this.authService.accessToken;

    if (!this.accessToken) {
      return this.handleRequest(request, next);
    }

    return this.authService.isAuthenticated$.pipe(
      filter((accessToken: string) => !!accessToken),
      switchMap((accessToken: string) => {
        if (accessToken) {
          this.accessToken = accessToken;

          return this.handleRequest(request, next);
        }
      }),
    );
  }

  private handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.cloneRequest(request)).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && this.accessToken) {
          return this.handleRefreshToken(request, next);
        }

        return throwError(error);
      }),
    );
  }

  private handleRefreshToken(request: HttpRequest<any>,
                             next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.refreshedAuthResult$.pipe(
      switchMap((authResult: any) => {
        if (authResult) {
          this.accessToken = authResult.accessToken;

          return next.handle(this.cloneRequest(request));
        }
      }),
    );
  }

  private cloneRequest(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      url: request.url,
      headers: this.setHeaders(),
    });
  }

  private setHeaders(): HttpHeaders {
    return new HttpHeaders()
      .append('Authorization', `Bearer ${this.accessToken}`)
      .append('Content-Type', 'application/json');
  }
}
