import { Component, OnInit } from '@angular/core';
import { HeaderMenuItem } from '../../models/header-menu-item';
import { HeaderMenuService } from '../../services/header-menu.service';

@Component({
  selector: 'dh-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {

  public mobileMenu: HeaderMenuItem[];

  constructor(private headerMenuService: HeaderMenuService) {
    this.mobileMenu = [];
  }

  async ngOnInit() {
    const mainMenu = await this.headerMenuService.getMainMenu();
    const userMenu = await this.headerMenuService.getUserMenu();

    this.mobileMenu.push(
      ...mainMenu,
      {
        label: 'menu-user.value',
        subItems: userMenu,
      });
  }

}
