import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ActionButtonModule } from '../../shared-layout/custom-ui-elements/action-button/action-button.module';
import { FormCheckboxModule } from '../../shared-layout/custom-ui-elements/form-checkbox/form-checkbox.module';
import { FormInputModule } from '../../shared-layout/custom-ui-elements/form-input/form-input.module';
import { IconsModule } from '../../shared-layout/icons/icons.module';
import { SharedLayoutModule } from '../../shared-layout/shared-layout.module';
import { TableActionButtonComponent } from './partials/table-action-button/table-action-button.component';
import { TablePaginatorComponent } from './partials/table-paginator/table-paginator.component';
import { TableSelectSectionComponent } from './partials/table-select-section/table-select-section.component';
import { HasActionButtonPipe } from './pipes/has-action-button.pipe';
import { TableComponent } from './table.component';
import { MainSharedModule } from '../../../../../../../src/app/shared/main-shared.module';

@NgModule({
  declarations: [
    TableComponent,
    TableActionButtonComponent,
    TableSelectSectionComponent,
    TablePaginatorComponent,
    HasActionButtonPipe,
  ],
  imports: [
    SharedLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    FormInputModule,
    IconsModule,
    ActionButtonModule,
    TranslateModule,
    FormInputModule,
    FormCheckboxModule,
    MainSharedModule,
  ],
  exports: [
    TableComponent,
    TableActionButtonComponent,
  ],
})
export class TableModule { }
