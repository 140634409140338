import { NgModule } from '@angular/core';
import { SharedLayoutModule } from '../../shared-layout.module';
import { FormTextareaComponent } from './form-textarea.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [FormTextareaComponent],
  imports: [
    SharedLayoutModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [FormTextareaComponent],
})
export class FormTextareaModule {
}
