import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '../../../WebApp/src/app/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

// Insert here all the providers, needed
const providers = [];

@NgModule({
  providers,
  declarations: [
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
  ],
})
export class AppModule {
}

@NgModule({})
export class MonitoringSharedModule {
  static forRoot(): ModuleWithProviders<MonitoringSharedModule> {
    return {
      providers,
      ngModule: AppModule,
    };
  }
}
