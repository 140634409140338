
export enum IconLayerType {
  TEXT = 'text',
  ICON = 'icon',
}

export interface IconLayer {
  type: IconLayerType;
  content?: string;
  style?: any;
  transform?: string;
  icon?: string;
  size?: string;
}
