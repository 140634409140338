import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormErrorsComponent } from './form-errors.component';
import { ErrorsToFormErrorsPipe } from './pipes/errors-to-form-errors.pipe';
import { SharedLayoutModule } from '../../shared-layout.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    FormErrorsComponent,
    ErrorsToFormErrorsPipe,
  ],
  imports: [
    CommonModule,
    SharedLayoutModule,
    TranslateModule,
  ],
  exports: [FormErrorsComponent],
})
export class FormErrorsModule { }
