<mat-toolbar fxLayout="row" fxLayoutAlign="space-between stretch" class="header-toolbar">
  <dh-header-logo routerLink="/"></dh-header-logo>

  <div class="menu" fxLayoutAlign="center center">
    <button mat-icon-button [matMenuTriggerFor]="main">
      <fa-icon icon="bars" size="2x"></fa-icon>
    </button>

    <mat-menu #main="matMenu">
      <ng-container *ngFor="let menuItem of mobileMenu">

        <!-- SUBMENU OF THE MAIN MENU ITEMS -->
        <a mat-menu-item
           *ngIf="menuItem.subItems"
           [mat-menu-trigger-for]="subMenu">{{menuItem.label | translate}}
        </a>
        <mat-menu #subMenu="matMenu">
          <a mat-menu-item *ngIf="menuItem.route"
             routerLink="{{menuItem.route}}">
            {{'menu-customers-delivery-overview.value' | translate}}
          </a>
          <ng-container *ngFor="let subMenuItem of menuItem.subItems">
            <a mat-menu-item
               *ngIf="subMenuItem.link; else subMenuItemEle"
               [href]="subMenuItem.link"
               target="_blank">
              {{subMenuItem.label | translate}}
            </a>
            <ng-template #subMenuItemEle>
              <a mat-menu-item
                 routerLink="{{subMenuItem.route}}">{{subMenuItem.label | translate}}
              </a>
            </ng-template>
          </ng-container>
        </mat-menu>

        <!-- MAIN MENU ITEMS WITHOUT SUBMENU -->
        <a mat-menu-item
           *ngIf="menuItem.link; else mainNoSub"
           [href]="menuItem.link"
           target="_blank">
          {{menuItem.label | translate}}
        </a>
        <ng-template #mainNoSub>
          <a mat-menu-item
             *ngIf="!menuItem.subItems"
             routerLink="{{menuItem.route}}">{{menuItem.label | translate}}
          </a>
        </ng-template>
      </ng-container>

    </mat-menu>
  </div>

</mat-toolbar>
