<div class="form-input-div" fxLayoutAlign="center center" fxLayout="column"
     [class.error]="controlDir.control.invalid && controlDir.control.touched">
  <input #custominput
         class="form-input"
         matTooltip="{{ placeholder }}"
         matTooltipPosition="above"
         [matDatepicker]="picker"
         [value]="value"
         (dateInput)="setValue($event)"
         (click)="opened = !opened; picker.open()"
         [placeholder]="placeholder"
         [disabled]="disabled"
         [min]="minDate"
         [max]="maxDate">
  <fa-icon class="custom-icon" *ngIf="!opened" icon="caret-down" matDatepickerToggleIcon></fa-icon>
  <fa-icon class="custom-icon" *ngIf="opened" icon="caret-up" matDatepickerToggleIcon></fa-icon>
  <mat-datepicker #picker (closed)="onTouched(); opened = false"></mat-datepicker>
</div>
<mat-error *ngIf="errorMsg && controlDir.control.invalid && controlDir.control.touched">{{errorMsg}}</mat-error>
