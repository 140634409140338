import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Permission } from '../../../../src/app/core/auth/models/permission.enum';
import { AuthGuard } from '../../../../src/app/core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'customers-and-delivery',
    loadChildren: () => import('./pages/customers-and-delivery/customers-and-delivery.module')
      .then(mod => mod.CustomersAndDeliveryModule),
    canActivate: [AuthGuard],
    data: {
      permission: Permission.DH_CUSTOMER_DELIVERY,
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
