<h1 class="label">{{ data?.label }}</h1>
<div class="text" *ngIf="data?.text">
  <dh-translation-text [text]="data?.text" (close)="close()"></dh-translation-text>
</div>
<dh-action-button
  *ngFor="let button of data?.buttons"
  [text]="button?.label"
  [type]="button?.type"
  [mat-dialog-close]="button?.returnValue">
</dh-action-button>
