import { Component, Input } from '@angular/core';
import { HeaderMenuItem } from '../../models/header-menu-item';

@Component({
  selector: 'dh-header-sub-item',
  templateUrl: './header-sub-item.component.html',
  styleUrls: ['./header-sub-item.component.scss'],
})
export class HeaderSubItemComponent {
  @Input() public item: HeaderMenuItem;
}
