import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StsAuthService } from 'sts-auth';
import { AuthResult } from 'sts-auth/lib/models/authResult';
import { environment } from '../../../../environments/environment';
import { Role } from '../../../core/auth/models/role.enum';
import { Auth0Result } from './model/auth0-result';
import { User } from './model/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  public userInformation$: BehaviorSubject<User>;

  private readonly ROLES_KEY = 'https://www.deltatre.com/roles';
  private readonly PERMISSIONS_KEY = 'https://www.deltatre.com/permissions';
  private readonly METADATA_KEY = 'https://www.deltatre.com/dh';

  constructor(private readonly authService: StsAuthService,
              private readonly router: Router) {
    this.init();
  }

  public init(): void {
    const user = this.getUser();
    const accessToken = this.authService.accessToken;

    if (user && accessToken) {
      this.userInformation$ = new BehaviorSubject(user);
      const authResult: AuthResult = { accessToken } as AuthResult;
      this.authService.getAuthResult$.next(authResult);
      this.authService.isAuthenticated$.next(accessToken);
    } else {
      this.userInformation$ = new BehaviorSubject(null);
      this.createUser();
    }

  }

  public hasRole(role: Role): Observable<boolean> {
    return this.userInformation$.pipe(
      map((user: User) => {
        return user &&
               Array.isArray(user.roles) &&
               !!user.roles.find((_role: Role) => _role === role);
      }),
    );
  }

  public hasPermission(permission: string): Observable<boolean> {
    return this.userInformation$.pipe(
      map((user: User) => {
        return user &&
               Array.isArray(user.permissions) &&
               !!user.permissions.find((_permission: string) => _permission === permission);
      }),
    );
  }

  private createUser(): void {
    this.authService.getAuthResult$.subscribe((authResult: AuthResult) => {
      if (authResult) {
        const payload = authResult.idTokenPayload;

        const user: User = {
          username: payload.name,
          roles: payload[this.ROLES_KEY],
          permissions: payload[this.PERMISSIONS_KEY],
          metaData: payload[this.METADATA_KEY],
        };

        localStorage.setItem('User', JSON.stringify(user));

        this.router.navigateByUrl(environment.auth0Config.routeTo);
        this.userInformation$.next(user);
      }
    });
  }

  private getUser(): User {
    return JSON.parse(localStorage.getItem('User'));
  }
}
