import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dh-headline',
  templateUrl: './headline.component.html',
  styleUrls: ['./headline.component.scss'],
})
export class HeadlineComponent implements OnInit {

  @Input() text: string;
  @Input() hasPadding = true;

  constructor() {
  }

  ngOnInit() {
  }

}
