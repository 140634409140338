import { NgModule } from '@angular/core';
import { SharedLayoutModule } from '../../shared/shared-layout/shared-layout.module';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    LoginComponent,
  ],
  imports: [
    TranslateModule,
    LoginRoutingModule,
    SharedLayoutModule,
  ]
})
export class LoginModule {
}
