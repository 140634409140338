import { TableSelectionOption } from './table-selection-option';
import { TemplateRef } from '@angular/core';

export class TableConfig {
  public filter?: boolean;
  public filterTextPlaceholder?: string;
  public filterSelects?: TableConfigFilterSelect[];
  public columns: TableConfigColumn[];
  public paginator?: TableConfigPaginator;
  public actionButtons?: TableConfigActionButton[];

  public static getFilterSelectCategory(): TableConfigFilterSelect {
    return {
      label: 'category-label.value',
      field: 'category',
      options: [
        { label: 'active.value', value: 'activated' },
        { label: 'inactive.value', value: 'deactivated' },
        { label: 'bin.value', value: 'bin' },
      ],
      value: 'activated',
    };
  }

  public static getAllActionButtons(): TableConfigActionButton[] {
    return [
      TableConfigActionButton.EDIT,
      TableConfigActionButton.ACTIVE,
      TableConfigActionButton.DELETE,
    ];
  }
}

export interface TableConfigFilterSelect {
  label: string;
  field: string;
  options: TableSelectionOption[];
  multiple?: boolean;
  value?: any;
  fullMatch?: boolean;
}

export interface TableConfigColumn {
  name: string;
  label: string;
  filter?: boolean;
  styleClass?: string;
  templateRef?: TemplateRef<any>;
}

export enum TableConfigActionButton {
  ACTIVE,
  EDIT,
  DELETE,
}

export interface TableConfigPaginator {
  pageSize: number;
  pageNumbers: number;
  showAll?: boolean;
}
