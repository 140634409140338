import { Component, Input } from '@angular/core';

@Component({
  selector: 'dh-table-action-button',
  templateUrl: './table-action-button.component.html',
  styleUrls: ['./table-action-button.component.scss'],
})
export class TableActionButtonComponent {
  @Input() public icon: string;
  @Input() public transform: string;
}
