import { NgModule } from '@angular/core';
import { SharedLayoutModule } from '../../shared-layout.module';
import { FormDatepickerComponent } from './form-datepicker.component';

@NgModule({
  declarations: [FormDatepickerComponent],
  imports: [SharedLayoutModule],
  exports: [FormDatepickerComponent],
})
export class FormDatepickerModule {
}
