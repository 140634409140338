<div
  *ngIf="length > 0 && (page | async) > 0"
  class="pagination"
  fxLayout="row wrap"
  fxLayoutAlign="end center"
  fxLayoutGap="5px">
  <button
    mat-stroked-button
    [class.disabled]="1 === (page | async)"
    (click)="setPage(1)">
    <fa-icon icon="step-backward"></fa-icon>
  </button>
  <button
    mat-stroked-button
    [class.disabled]="1 === (page | async)"
    (click)="setPage(page.getValue() - 1)">
    <fa-icon icon="caret-left"></fa-icon>
  </button>
  <button
    mat-stroked-button
    *ngFor="let _page of pages(page | async)"
    [class.active]="_page === (page | async)"
    (click)="setPage(_page)">
    {{ _page }}
  </button>
  <button
    mat-stroked-button
    [class.disabled]="totalPages === (page | async)"
    (click)="setPage(page.getValue() + 1)">
    <fa-icon icon="caret-right"></fa-icon>
  </button>
  <button
    mat-stroked-button
    [class.disabled]="totalPages === (page | async)"
    (click)="setPage(totalPages)">
    <fa-icon icon="step-forward"></fa-icon>
  </button>
</div>

<div fxLayout="row wrap" fxLayoutAlign="start end" fxLayoutGap="10px" class="pagination">
  <dh-action-button
    [text]="'show-all.value' | translate"
    [type]="actionButton.ADD"
    (click)="showAll()"></dh-action-button>
  <dh-form-select
    fxFlex="215px"
    [selections]="pageSizeOptions"
    [(ngModel)]="pageSize"
    (ngModelChange)="setPageSize($event)">
  </dh-form-select>
</div>
