import { Component, EventEmitter, Input, Output, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'dh-translation-text',
  templateUrl: './translation-text.component.html',
  styleUrls: ['./translation-text.component.scss'],
})
export class TranslationTextComponent {

  @Input() public text: string;

  @Output() public close: EventEmitter<void> = new EventEmitter();

  constructor(private router: Router) { }

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    if (event.target instanceof HTMLAnchorElement) {
      event.preventDefault();
      const target = event.target;
      this.router.navigate([target.pathname]);
    }
  }

}
