import { Pipe, PipeTransform } from '@angular/core';
import { FormSelect } from '../../../../../../../../../src/app/shared/shared-layout/custom-ui-elements/form-select/model/form-select';

@Pipe({
  name: 'getByValue',
})
export class GetByValuePipe implements PipeTransform {

  transform(formSelects: FormSelect[], value: any): FormSelect {
    return (formSelects || []).find((formSelect: FormSelect) => formSelect.value === value);
  }

}
