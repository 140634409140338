import { NgModule } from '@angular/core';
import { MainSharedModule } from '../../shared/main-shared.module';
import { FooterComponent } from './footer.component';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    MainSharedModule,
  ],
  exports: [
    FooterComponent,
  ],
})
export class FooterModule {
}
