import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedLayoutModule } from '../../shared-layout.module';
import { FormInputComponent } from './form-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    FormInputComponent,
  ],
  imports: [
    SharedLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [
    FormInputComponent,
  ],
})
export class FormInputModule {
}
