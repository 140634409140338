import { NgModule } from '@angular/core';
import { SharedLayoutModule } from '../../shared-layout.module';
import { IconButtonComponent } from './icon-button.component';

@NgModule({
  declarations: [IconButtonComponent],
  imports: [
    SharedLayoutModule,
  ],
  exports: [IconButtonComponent],
})
export class IconButtonModule {
}
