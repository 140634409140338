import { NgModule } from '@angular/core';
import { MainSharedModule } from '../../shared/main-shared.module';
import { LogoutRoutingModule } from './logout-routing.module';
import { LogoutComponent } from './logout.component';

@NgModule({
  declarations: [LogoutComponent],
  imports: [
    LogoutRoutingModule,
    MainSharedModule,
  ],
})
export class LogoutModule {
}
