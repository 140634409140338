import { NgModule } from '@angular/core';
import { SharedLayoutModule } from '../../../../projects/WebApp/src/app/shared/shared-layout/shared-layout.module';
import { AuthCallbackRoutingModule } from './auth-callback-routing.module';
import { AuthCallbackComponent } from './auth-callback.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AuthCallbackComponent],
  imports: [
    TranslateModule,
    AuthCallbackRoutingModule,
    SharedLayoutModule,
  ]
})
export class AuthCallbackModule {
}
