// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  federation: 'DFL',
  hasUserGroup: true,
  api: 'http://localhost:5050/api/v1',
  monitoringApi: 'https://api.monitoring.integration.datahub-sts.de/api/v1',
  signalR: {
    monitoring: 'https://api.monitoring.integration.datahub-sts.de/hub/v1',
    webApp: 'https://api.webapp.integration.datahub-sts.de/hub/v1',
  },
  userManagementUrl: 'https://sts-auth-admin-dev.azurewebsites.net/',
  auth0Config: {
    clientID: 'J56YptpLNAlMv6RtpF00x6SMFDcgjY4m',
    domain: 'sportecsolutionsdev.eu.auth0.com',
    responseType: 'token id_token',
    audience: 'https://dh-web-dl-api.azurewebsites.net',
    redirectUri: 'http://localhost:4200/callback',
    returnTo: 'http://localhost:4200',
    scope: 'openid profile email dh:user',
    routeTo: '/',
  },
};
