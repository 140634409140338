import { Pipe, PipeTransform } from '@angular/core';
import { FormError } from '../../../../../pages/customer-create/models/form-error';

@Pipe({
  name: 'errorsToFormErrors',
})
export class ErrorsToFormErrorsPipe implements PipeTransform {

  transform(value: any): FormError[] {
    return Object.keys(value || {})
      .reduce(
        (errors: FormError[], key: string) => {
          if (Array.isArray(value[key])) {
            value[key].forEach((error: FormError) => {
              errors.push({
                error: key,
                at: Number(error.at) + 1,
              });
            });
          } else {
            errors.push({ error: key });
          }
          return errors;
        },
        []);
  }

}
