import { Component, EventEmitter, forwardRef, Input, Optional, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlContainer, FormControlDirective, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { CanDisable } from '@angular/material/core';
import { BaseFormComponent } from '../../../../../../../../src/app/shared/shared-layout/custom-ui-elements/base-form/base-form.component';
import { BaseFormElement } from '../../../../../../../../src/app/shared/shared-layout/custom-ui-elements/base-form/models/base-form-element';

@Component({
  selector: 'dh-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputComponent),
      multi: true,
    },
  ],
})
export class FormInputComponent extends BaseFormComponent {
  @ViewChild(FormControlDirective, { static: true }) public formControlDirective: FormControlDirective;

  @Input() public type = 'text';
  @Input() public pattern: string = null;
  @Input() public label: string = null;
  @Input() public placeholder: string;
  @Input() public maxlength: number = 50;
  @Input() public isReadOnly: boolean = false;
  @Input() public tooltip: string;
  @Input() public isPassword: boolean = false;

  @Output() onShowHiddenText = new EventEmitter<boolean>();

  hideText: boolean = false;

  constructor(@Optional() protected controlContainer: ControlContainer) {
    super(controlContainer);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isPassword) {
      this.hideText = this.isPassword;
      this.toogleType();
    }
  }

  onClickEye(): void {
    if (this.isPassword) {
      this.hideText = !this.hideText;
      this.toogleType();
      this.onShowHiddenText.emit(!this.hideText);
    }
  }

  private toogleType(): void {
    if (this.hideText) {
      this.type = 'password';
    } else {
      this.type = 'text';
    }
  }

  protected getFormElementValueAccessor(): BaseFormElement {
    return this.formControlDirective.valueAccessor;
  }

  protected getFormElement(): CanDisable {
    return this.formControlDirective;
  }

  protected setValidator() {
    const validators: ValidatorFn[] = this.control.validator ? [this.control.validator] : [];
    if (this.isRequired) {
      validators.push(Validators.required);
    }
    if (this.pattern) {
      validators.push(Validators.pattern(this.pattern));
    }

    this.control.setValidators(validators);
    this.control.updateValueAndValidity();
  }
}
