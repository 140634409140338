import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CustomDateAdapter extends MomentDateAdapter {

  public setLocale(locale: string): void {
    // TODO: dynamic localization

    super.setLocale('de');
  }

  // parse(value: any, parseFormat: string | string[]): Moment | null {
  //   const dateFormat: string | string[] = 'DD.MM.YYYY' || parseFormat;
  //   return super.parse(value, dateFormat);
  // }
  //
  // format(date: Moment, displayFormat: string): string {
  //   const dateFormat: string | string[] = 'DD.MM.YYYY' || displayFormat;
  //   return super.format(date, dateFormat);
  // }
}
