import { Component, OnInit } from '@angular/core';
import { HeaderMenuItem } from '../../models/header-menu-item';
import { HeaderMenuService } from '../../services/header-menu.service';

@Component({
  selector: 'dh-header-user-menu',
  templateUrl: './header-user-menu.component.html',
  styleUrls: ['./header-user-menu.component.scss'],
})
export class HeaderUserMenuComponent implements OnInit {
  public userMenu: HeaderMenuItem[];

  constructor(private headerMenuService: HeaderMenuService) {
  }

  public ngOnInit(): void {
    this.getUserMenu();
  }

  private async getUserMenu(): Promise<void> {
    this.userMenu = await this.headerMenuService.getUserMenu();
  }
}
