import { Component, forwardRef, Input, Optional, ViewChild } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { CanDisable } from '@angular/material/core';
import { BaseFormComponent } from '../../../../../../../../src/app/shared/shared-layout/custom-ui-elements/base-form/base-form.component';
import { BaseFormElement } from '../../../../../../../../src/app/shared/shared-layout/custom-ui-elements/base-form/models/base-form-element';

@Component({
  selector: 'dh-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCheckboxComponent),
      multi: true,
    },
  ],
})
export class FormCheckboxComponent extends BaseFormComponent {

  @ViewChild(MatCheckbox, { static: true }) public checkbox: MatCheckbox;
  @Input() public label: string;
  @Input() public errorMsg: string;
  @Input() public value: boolean;
  @Input() public id: string;

  constructor(@Optional() protected controlContainer: ControlContainer) {
    super(controlContainer);
  }

  protected getFormElementValueAccessor(): BaseFormElement {
    return this.checkbox;
  }

  protected getFormElement(): CanDisable {
    return this.checkbox;
  }
}
