import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HasPermissionModule } from './pipes/has-permission/has-permission.module';
import { SharedLayoutModule } from './shared-layout/shared-layout.module';
import { FormSelectModule } from './shared-layout/custom-ui-elements/form-select/form-select.module';
import { SectionModule } from './shared-layout/custom-ui-elements/section/section.module';
import { WeblibSvgLogoModule } from './pipes/weblib-svg-logo/weblib-svg-logo.module';
import { IconButtonModule } from './shared-layout/custom-ui-elements/icon-button/icon-button.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    SharedLayoutModule,
    HasPermissionModule,
    FormSelectModule,
    SectionModule,
    IconButtonModule,
    WeblibSvgLogoModule,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    SharedLayoutModule,
    HasPermissionModule,
    FormSelectModule,
    SectionModule,
    IconButtonModule,
    WeblibSvgLogoModule,
  ],
})
export class MainSharedModule {
}
