import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../../src/app/core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'monitoring',
    loadChildren: () => import('./pages/monitoring/monitoring.module')
      .then(mod => mod.MonitoringModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
