<div class="login" fxFlex fxLayoutAlign="center center">

  <div class="login-div" fxLayout="column" fxLayoutAlign="center center">
    <div class="login-logo" fxLayoutAlign="center center">
      <img src="./assets/logo/sportec.png"/>
    </div>


    <div class="login-content" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="15px">
      <span class="login-title">Data Hub</span>
      <div class="login-text">
        {{'authentication.login-text' | translate}}
      </div>
    </div>

    <div class="login-button-div"
         fxFlex
         fxLayoutAlign="center center"
         fxLayout="row"
         fxLayoutGap="10px"
         (click)="login()">
      <button mat-button class="login-button">
        {{'authentication.login-button' | translate}}
      </button>
      <div>
        <fa-icon icon="angle-right"></fa-icon>
      </div>
    </div>
  </div>
</div>
