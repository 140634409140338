<div class="form-select-search" fxLayoutAlign="center center">
  <ngx-select-dropdown #customselect
                       fxFlexFill
                       matTooltip="{{ config?.placeholder || '' }}"
                       matTooltipPosition="above"
                       [ngModel]="selections | getByValue: value"
                       [config]="config"
                       [options]="selections"
                       [class.error]="control?.invalid && control?.touched"
                       (open)="opened = true"
                       (close)="opened = false"
                       (focusout)="opened = false; control?.markAsTouched()"
                       (change)="onChange(customselect?.value?.value)">
  </ngx-select-dropdown>
  <mat-spinner class="spinner" *ngIf="!selections" [diameter]="20"></mat-spinner>
  <fa-icon class="custom-icon" *ngIf="!opened && selections" icon="caret-down"></fa-icon>
  <fa-icon class="custom-icon opened" *ngIf="opened && selections" icon="caret-up"></fa-icon>
</div>
