import { Component, Input } from '@angular/core';

@Component({
  selector: 'dh-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent {
  @Input() public title: string;
  @Input() public numberOfItems: number;
  @Input() public layoutGap: string = '20px';
}
