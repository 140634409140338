import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class TranslateApiLoaderService implements TranslateLoader {

  constructor(private http: HttpClient,
              private prefix: string = '',
              private suffix: string = '') {
  }

  public getTranslation(lang: string): Observable<any> {
    return this.http.get(`${this.prefix}${lang}${this.suffix}`)
      .pipe(
        map((translationsData: { translations: any }) =>
          translationsData.translations || {}),
      );
  }
}
