<div class="home" fxLayout="row wrap">
  <ng-container *ngFor="let section of buttonSections$ | async">
    <dh-section
      *ngIf="section.permission | hasPermission | async"
      class="home-section"
      [title]="section.title"
      fxFlex="1 0 50%">
      <div class="home-button-container">
        <div fxLayout="row wrap" fxFlexFill fxLayoutAlign="start center" fxLayoutGap="18px grid">
          <ng-container *ngFor="let button of section.iconButtons">
            <dh-icon-button
              *ngIf="button.permission | hasPermission | async"
              class="home-button"
              [style]="'rectangle'"
              [url]="button.url"
              [routerLink]="button.route"
              [icon]="button.icon"
              [label]="button.label"
              [iconLayers]="button.iconLayers"></dh-icon-button>
          </ng-container>
        </div>
      </div>
    </dh-section>
  </ng-container>
</div>
