import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'getFormGroupFromFormArray',
})
export class GetFormGroupFromFormArrayPipe implements PipeTransform {

  public transform(formArray: UntypedFormArray, field: string, value: string): UntypedFormGroup {
    if (formArray && field && value !== undefined) {
      return formArray.controls.find((formGroup: UntypedFormGroup) =>
        formGroup &&
        formGroup.get(field) &&
        formGroup.get(field).value === value) as UntypedFormGroup;
    }
    return undefined;
  }

}
