import { Component, forwardRef, Input, Optional, ViewChild } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CanDisable } from '@angular/material/core';
import { MatRadioGroup } from '@angular/material/radio';
import { BaseFormComponent } from '../../../../../../../../src/app/shared/shared-layout/custom-ui-elements/base-form/base-form.component';
import { BaseFormElement } from '../../../../../../../../src/app/shared/shared-layout/custom-ui-elements/base-form/models/base-form-element';
import { FormRadiobutton } from './models/form-radiobutton';

@Component({
  selector: 'dh-form-radiobuttons',
  templateUrl: './form-radiobuttons.component.html',
  styleUrls: ['./form-radiobuttons.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormRadiobuttonsComponent),
      multi: true,
    },
  ],
})
export class FormRadiobuttonsComponent extends BaseFormComponent {

  @ViewChild(MatRadioGroup, { static: true }) public radioGroup: MatRadioGroup;
  @Input() public radioButtons: FormRadiobutton[] = [];
  @Input() public flexDirection: string = 'row wrap';

  constructor(@Optional() protected controlContainer: ControlContainer) {
    super(controlContainer);
  }

  protected getFormElementValueAccessor(): BaseFormElement {
    return this.radioGroup;
  }

  protected getFormElement(): CanDisable {
    return this.radioGroup;
  }

}
