import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapToKey',
})
export class MapToKeyPipe implements PipeTransform {

  public transform(value: any[], key: string): any[] {
    return (value || []).map(v => v[key]);
  }

}
