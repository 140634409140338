import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  UntypedFormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CanDisable } from '@angular/material/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  template: '',
})
export abstract class BaseFormComponent implements OnInit, ControlValueAccessor, OnDestroy {

  @Input() public formControl: UntypedFormControl;
  @Input() public formControlName: string;
  @Input() public isRequired: boolean = false;

  @Input()
  public set isDisabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }

  public get isDisabled(): boolean {
    return this.getFormElement().disabled;
  }

  public value: any;
  public onDestroy$: Subject<void>;

  public get control(): UntypedFormControl | AbstractControl {
    return this.formControl ||
      (this.controlContainer && this.controlContainer.control ?
        this.controlContainer.control.get(this.formControlName) : undefined);
  }

  protected isNgModel: boolean;

  constructor(@Optional() protected controlContainer: ControlContainer) {
    this.onDestroy$ = new Subject();
  }

  public ngOnInit(): void {
    if (!this.control) {
      this.formControl = new UntypedFormControl('');
      this.isNgModel = true;
    }
    this.setValidator();
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  /*
   CONTROL VALUE ACCESSOR METHODS
   */
  public writeValue(value: any): void {
    this.getFormElementValueAccessor().writeValue(value);
  }

  public registerOnChange(fn: any): void {
    if (this.isNgModel) {
      this.formControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(fn);
    } else {
      this.getFormElementValueAccessor().registerOnChange(fn);
    }
  }

  public registerOnTouched(fn: any): void {
    this.getFormElementValueAccessor().registerOnTouched(fn);
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.getFormElementValueAccessor().setDisabledState(isDisabled);
  }

  protected abstract getFormElementValueAccessor(): ControlValueAccessor;

  protected abstract getFormElement(): CanDisable;

  protected setValidator(): void {
    const validators: ValidatorFn[] = this.control.validator ? [this.control.validator] : [];
    if (this.isRequired) {
      validators.push(Validators.required);
    }

    this.control.setValidators(validators);
    this.control.updateValueAndValidity();
  }

}
