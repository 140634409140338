import { NgModule } from '@angular/core';
import { SharedLayoutModule } from '../../shared-layout.module';
import { HeadlineComponent } from './headline.component';

@NgModule({
  declarations: [HeadlineComponent],
  imports: [SharedLayoutModule],
  exports: [HeadlineComponent],
})
export class HeadlineModule {
}
