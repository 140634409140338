import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { environmentLoader as environmentLoaderPromise } from './environments/environmentLoader';

environmentLoaderPromise.then((env) => {
  if (env.production) {
    enableProdMode();
  }
  environment.production = env.production;
  environment.federation = env.federation;
  environment.hasUserGroup = env.hasUserGroup;
  environment.api = env.api;
  environment.monitoringApi = env.monitoringApi;
  environment.signalR = env.signalR;
  environment.userManagementUrl = env.userManagementUrl;
  environment.auth0Config = env.auth0Config;

  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
