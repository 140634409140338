import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ENVIRONMENT, StsAuthModule } from 'sts-auth';
import { MonitoringSharedModule } from '../../projects/Monitoring/src/app/app.module';
import { WebAppSharedModule } from '../../projects/WebApp/src/app/app.module';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ApiPrefixInterceptor } from './core/interceptor/api.interceptor';
import { TranslateApiLoaderService } from './core/translate/services/translate-api-loader.service';
import { AuthCallbackModule } from './pages/auth-callback/auth-callback.module';
import { HomeModule } from './pages/home/home.module';
import { LoginModule } from './pages/login/login.module';
import { LogoutModule } from './pages/logout/logout.module';

// AoT requires an exported function for factories
// tslint:disable-next-line:function-name
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateApiLoaderService(
    http,
    // `${environment.contentApi}/translations?isocode=`,
    '/assets/translate/',
    '.json');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    StsAuthModule,
    CoreModule,
    AppRoutingModule,
    HomeModule,
    LogoutModule,
    LoginModule,
    AuthCallbackModule,
    WebAppSharedModule.forRoot(),
    MonitoringSharedModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: ENVIRONMENT, useValue: environment,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
  }
}
