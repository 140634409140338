import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HeaderMenuItem } from '../../models/header-menu-item';
import { HeaderMenuService } from '../../services/header-menu.service';

@Component({
  selector: 'dh-header-sub-menu',
  templateUrl: './header-sub-menu.component.html',
  styleUrls: ['./header-sub-menu.component.scss'],
})
export class HeaderSubMenuComponent implements OnInit {
  public subMenu: Promise<HeaderMenuItem[]>;

  private menuSub$: Subscription;

  constructor(private headerMenuService: HeaderMenuService) {
  }

  public ngOnInit(): void {
    this.subMenu = this.headerMenuService.getSubMenuItemsAtStart();
    this.getSubMenu();
  }

  public ngOnDestroy(): void {
    this.menuSub$.unsubscribe();
  }

  private getSubMenu(): void {
    this.menuSub$ = this.headerMenuService.getSubMenu().subscribe((subMenu: Promise<HeaderMenuItem[]>) => {
      this.subMenu = subMenu;
    });
  }
}
