import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapToKeyPipe } from './map-to-key.pipe';

@NgModule({
  declarations: [MapToKeyPipe],
  imports: [
    CommonModule,
  ],
  exports: [MapToKeyPipe],
})
export class MapToKeyModule { }
