import { Pipe, PipeTransform } from '@angular/core';
import { WeblibTypeEnum } from '../../services/weblib-image/model/weblib-type.enum';
import { WeblibImageService } from '../../services/weblib-image/weblib-image.service';

@Pipe({
  name: 'weblibSvgLogo',
})
export class WeblibSvgLogoPipe implements PipeTransform {

  constructor(private weblibSvgLogo: WeblibImageService) {
  }

  public transform(svgId: number | string = '', kind: WeblibTypeEnum): string {
    switch (kind) {
      case WeblibTypeEnum.TEAM:
        return `${this.weblibSvgLogo.svgLogoUrl}${svgId}.svg`;
      case WeblibTypeEnum.COMPETITION:
        return `${this.weblibSvgLogo.svgCompetitionUrl}${svgId}.svg`;
      default:
        return '';
    }
  }

}
