import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedLayoutModule } from '../../../../../projects/WebApp/src/app/shared/shared-layout/shared-layout.module';
import { TranslationTextComponent } from './translation-text.component';

@NgModule({
  declarations: [TranslationTextComponent],
  imports: [
    CommonModule,
    SharedLayoutModule,
    RouterModule,
  ],
  exports: [TranslationTextComponent],
})
export class TranslationTextModule { }
