import { Component, OnInit } from '@angular/core';
import { HeaderMenuItem } from '../../models/header-menu-item';
import { HeaderMenuService } from '../../services/header-menu.service';

@Component({
  selector: 'dh-header-main-menu',
  templateUrl: './header-main-menu.component.html',
  styleUrls: ['./header-main-menu.component.scss'],
})
export class HeaderMainMenuComponent implements OnInit {
  public mainMenu: HeaderMenuItem[];

  constructor(private headerMenuService: HeaderMenuService) {
  }

  public ngOnInit(): void {
    this.getMainMenu();
  }

  private async getMainMenu(): Promise<void> {
    this.mainMenu = await this.headerMenuService.getMainMenu();
  }
}
