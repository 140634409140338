import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User } from '../../shared/services/user/model/user';
import { UserService } from '../../shared/services/user/user.service';
import { Permission } from '../auth/models/permission.enum';
import { Role } from '../auth/models/role.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(private readonly userService: UserService,
              private readonly router: Router) {
  }

  public canActivate(next: ActivatedRouteSnapshot,
                     state: RouterStateSnapshot): Observable<boolean> {
    const isLoggingIn: boolean = Boolean(localStorage.getItem('isLoggingIn'));
    return this.userService.userInformation$.pipe(
      map((user: User) => {
        if (user) {
          localStorage.removeItem('isLoggingIn');
          if (state.url.indexOf('callback') > -1) {
            this.router.navigateByUrl(environment.auth0Config.routeTo);
            return false;
          }

          if (user.roles.find((role: Role) => role === Role.USER)) {
            const hasPermission = user.permissions.find((permission: Permission) => {
              return permission === next.data.permission;
            });

            if (!next.data.permission || (next.data.permission && hasPermission)) {
              return true;
            }
            this.router.navigateByUrl(environment.auth0Config.routeTo);
            return false;
          }
          if (state.url.indexOf('logout') > -1) {
            return true;
          }

          this.router.navigateByUrl('/login');
          return false;
        }

        if (state.url.indexOf('callback') > -1 && isLoggingIn) {
          return true;
        }

        this.router.navigateByUrl('/login');
        return false;
      }),
    );
  }

}
