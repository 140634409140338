import { Component, forwardRef, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CanDisable } from '@angular/material/core';
import { NgxSelectDropdownComponent } from 'ngx-select-dropdown';
import { BaseFormComponent } from '../../../../../../../../src/app/shared/shared-layout/custom-ui-elements/base-form/base-form.component';
import { BaseFormElement } from '../../../../../../../../src/app/shared/shared-layout/custom-ui-elements/base-form/models/base-form-element';
import { FormSelect } from '../../../../../../../../src/app/shared/shared-layout/custom-ui-elements/form-select/model/form-select';

@Component({
  selector: 'dh-form-select-search',
  templateUrl: './form-select-search.component.html',
  styleUrls: ['./form-select-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSelectSearchComponent),
      multi: true,
    },
  ],
})
export class FormSelectSearchComponent extends BaseFormComponent implements OnInit, ControlValueAccessor {

  @ViewChild(NgxSelectDropdownComponent, { static: true }) public selection: NgxSelectDropdownComponent;

  @Input() public selections: FormSelect[];
  @Input() public isRequired: boolean = false;
  @Input() public errorMsg: string;
  @Input() public config: any;

  @Input()
  public set isDisabled(isDisabled: boolean) {
    this.selection.disabled = isDisabled;
  }

  public get isDisabled(): boolean {
    return this.selection.disabled;
  }

  public value: any;
  public opened: boolean;
  public filter: string;
  public disabled: boolean;

  public onChange: (value: any) => void;
  public onTouched: () => void;

  constructor(@Optional() protected controlContainer: ControlContainer) {
    super(controlContainer);
  }

  protected getFormElementValueAccessor(): BaseFormElement {
    return this;
  }

  protected getFormElement(): CanDisable {
    return this;
  }

  /*
   CONTROL VALUE ACCESSOR METHODS
   */
  public writeValue(value: string): void {
    if (value) {
      this.value = value;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.selection.disabled = isDisabled;
  }

}
