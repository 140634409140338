import { NgModule } from '@angular/core';
import { SharedLayoutModule } from '../../shared-layout.module';
import { SubHeadlineComponent } from './sub-headline.component';

@NgModule({
  declarations: [
    SubHeadlineComponent,
  ],
  imports: [
    SharedLayoutModule,
  ],
  exports: [
    SubHeadlineComponent,
  ],

})
export class SubHeadlineModule {
}
