import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CanDisable } from '@angular/material/core';
import { BaseFormComponent } from '../base-form/base-form.component';
import { FormSelect } from './model/form-select';

@Component({
  selector: 'dh-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSelectComponent),
      multi: true,
    },
  ],
})
export class FormSelectComponent extends BaseFormComponent implements OnChanges, ControlValueAccessor {

  @Input() public selections: FormSelect[];
  @Input() public label: string;
  @Input() public errorMsg: string;
  @Input() public showDefault: boolean = false;

  @Input()
  public set isDisabled(isDisabled: boolean) {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.control && this.control.disable({ onlySelf: true, emitEvent: false });
    } else {
      this.control && this.control.enable({ onlySelf: true, emitEvent: false });
    }
  }

  @Output() public selectionChange: EventEmitter<string>;

  public get isDisabled(): boolean {
    return this.disabled;
  }

  public disabled: boolean;

  public value: string = '';
  public opened: boolean;

  constructor(@Optional() protected controlContainer: ControlContainer) {
    super(controlContainer);
    this.isDisabled = !this.selections || this.selections.length === 0;
    this.selectionChange = new EventEmitter<string>();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const selectionsChange = changes.selections;
    const selections = selectionsChange?.currentValue;
    this.isDisabled = !selections || selections.length === 0;
  }

  public writeValue(_value: string): void {
    this.value = _value;
  }

  public registerOnChange(fn: any): void {
    this.changeValue = fn;
  }

  public registerOnTouched(fn: any): void {
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public changeValue(value: string): void {
  }

  protected getFormElementValueAccessor(): ControlValueAccessor {
    return undefined;
  }

  protected getFormElement(): CanDisable {
    return undefined;
  }

}
