import { Component, Input } from '@angular/core';
import { ApiErrors } from './models/api-errors';

@Component({
  selector: 'dh-api-errors',
  templateUrl: './api-errors.component.html',
  styleUrls: ['./api-errors.component.scss'],
})
export class ApiErrorsComponent {

  @Input() public apiErrors: ApiErrors;

  public getErrors(): string[] {
    return this.apiErrors && this.apiErrors.errors
      ? Object.keys(this.apiErrors.errors)
        .reduce((arr: string[], key: string) => arr.concat(this.apiErrors.errors[key]), [])
      : [];
  }

}
