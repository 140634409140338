import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../../services/user/user.service';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'hasPermission',
})
export class HasPermissionPipe implements PipeTransform {

  constructor(
    private user: UserService,
  ) {}

  public transform(permission: string): Observable<boolean> {
    return permission ? this.user.hasPermission(permission) : of(true);
  }

}
