import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeblibSvgLogoPipe } from './weblib-svg-logo.pipe';

@NgModule({
  declarations: [WeblibSvgLogoPipe],
  imports: [
    CommonModule,
  ],
  exports: [WeblibSvgLogoPipe],
})
export class WeblibSvgLogoModule { }
