import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StsAuthService } from 'sts-auth';

@Component({
  selector: 'dh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private authService: StsAuthService,
              private translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current
    // language
    this.translate.setDefaultLang('de-DE');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const language: string = this.translate.getBrowserCultureLang().substring(0,2) === 'de' ? 'de-DE' : 'en-US';
    this.translate.use(language);
    moment.locale(this.translate.currentLang);
  }

  public isLoggedIn(): Observable<boolean> {
    return this.authService.isAuthenticated$.pipe(
      filter((accessToken: string) => !!accessToken),
      map(() => {
        return true;
      }),
    );
  }
}
