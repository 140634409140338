import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActionButtonType } from './enum/action-button-type.enum';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dh-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
})
export class ActionButtonComponent implements OnInit {

  @Input() text: string;
  @Input() disabled: boolean;
  @Input() selected: boolean;
  @Input() type: ActionButtonType = ActionButtonType.CANCEL;
  @Input() showSpinner: boolean = false;
  @Output() onButtonClick: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit() {
  }

}
