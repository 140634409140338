import { Component, OnInit, Input, Self, Optional } from '@angular/core';
import { TableSelectionOption } from '../../models/table-selection-option';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'dh-table-select-section',
  templateUrl: './table-select-section.component.html',
  styleUrls: ['./table-select-section.component.scss'],
})
export class TableSelectSectionComponent implements OnInit, ControlValueAccessor {
  @Input() public title: string;
  @Input() public options: TableSelectionOption[];
  @Input() public multiple: boolean;
  @Input() public disabled: boolean;
  private onChange: Function;
  private pValue: any | any[];

  public get value(): any | any[] {
    return this.pValue;
  }

  public set value(value: any | any[]) {
    this.pValue = value;
  }

  constructor(@Optional() @Self() public controlDir: NgControl) {
    this.controlDir.valueAccessor = this;
  }

  public ngOnInit(): void {
  }

  public select(option: TableSelectionOption): void {
    if (option.callback) {
      option.callback();
    }
    if (this.multiple) {
      if (!Array.isArray(this.value)) {
        this.value = [];
      }
      if ((<any[]>this.value).includes(option.value)) {
        const index: number = (<any[]>this.value).indexOf(option.value);
        (<any[]>this.value).splice(index, 1);
      } else {
        this.value.push(option.value);
      }
    } else {
      this.value = option.value;
    }
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  public selected(option: TableSelectionOption): boolean {
    return (Array.isArray(this.value) && this.value.includes(option.value)) ||
      (this.value === option.value);
  }

  public writeValue(value: any): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
