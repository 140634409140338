<div class="section" fxLayout="column" [fxLayoutGap]="layoutGap">
  <div class="section-header" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
    <div class="section-title">{{ title }}</div>
    <div *ngIf="numberOfItems" class="counter" fxLayoutAlign="center center">
      <span>{{numberOfItems}}</span>
    </div>
  </div>
  <ng-content></ng-content>
</div>

