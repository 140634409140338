export enum Permission {
  DH_CONTROL = 'dh:control',
  DH_CUSTOMER_DELIVERY = 'dh:customer_delivery',
  DH_CUSTOMER_DELIVERY_CONTACTS = 'dh:customer_delivery:contacts',
  DH_CUSTOMER_DELIVERY_CUSTOMERS = 'dh:customer_delivery:customers',
  DH_CUSTOMER_DELIVERY_DELIVERY_TASKS = 'dh:customer_delivery:delivery_tasks',
  DH_CUSTOMER_DELIVERY_FEEDS = 'dh:customer_delivery:feeds',
  DH_CUSTOMER_DELIVERY_GROUPS = 'dh:customer_delivery:groups',
  DH_CUSTOMER_DELIVERY_PROJECTS = 'dh:customer_delivery:projects',
  DH_LIVE = 'dh:live',
  DH_QM = 'dh:qm',
  DH_ADMIN = 'dh:admin',
  DH_SYSTEM = 'dh:system',
}
