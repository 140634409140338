import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MonitoringSharedModule } from '../../projects/Monitoring/src/app/app.module';
import { WebAppSharedModule } from '../../projects/WebApp/src/app/app.module';
import { AuthGuard } from './core/guards/auth.guard';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    WebAppSharedModule.forRoot(),
    MonitoringSharedModule.forRoot(),
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
