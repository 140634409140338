import { animate, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, Observable, of, Subject } from 'rxjs';
import { catchError, startWith, switchMap, takeUntil } from 'rxjs/operators';
import {
  TableConfig,
  TableConfigActionButton,
  TableConfigColumn,
  TableConfigFilterSelect,
} from './models/table-config';
import { TableData, TableDataEntry } from './models/table-data';
import { TableRequest } from './models/table-request';
import { TableActionButtonComponent } from './partials/table-action-button/table-action-button.component';
import { TablePaginatorComponent } from './partials/table-paginator/table-paginator.component';

@Component({
  selector: 'dh-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      transition(':enter', [
        style({ height: '0px', minHeight: '0' }),
        animate(225, style({ height: '*' })),
      ]),
      transition(':leave', [
        animate(225, style({ height: '0px' })),
      ]),
    ]),
  ],
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {

  public tableConfigActionButton = TableConfigActionButton;

  @Input()
  public set tableConfig(tableConfig: TableConfig) {
    this._tableConfig = {
      ...this._tableConfig,
      ...tableConfig,
    };
  }

  public get tableConfig(): TableConfig {
    return this._tableConfig;
  }

  @Input() public getData: ((request: TableRequest<TableDataEntry>) => Observable<TableData>);
  public dataSource: MatTableDataSource<TableDataEntry>;

  public get displayedColumns(): string[] {
    return []
      .concat(
        this.isSelectable ? ['select'] : [],
        this.tableConfig.columns.map((column: TableConfigColumn) => column.name),
        ['action-buttons'],
      );
  }

  @Input() public isLoadingResults: boolean = false;
  @Input() public hidePagination: boolean = false;
  @Input() public isSelectable: boolean = false;
  @Input() public isExpandable: boolean = false;
  @Input() public hasMultipleExpansions: boolean = false;
  @Input() public filterChanged: EventEmitter<void>;
  @Input() public selection: SelectionModel<string>;

  @Output() public action: EventEmitter<[TableConfigActionButton, TableDataEntry]>;
  @Output() public selectionChange: EventEmitter<SelectionModel<string | number>>;

  @ViewChild('paginator', { static: false }) public paginator: TablePaginatorComponent;
  @ViewChild(MatSort, { static: false }) public sort: MatSort;

  @ContentChild('expandedDetailRow', { static: false }) public expandedDetailRow: TemplateRef<any>;
  @ContentChild('headerButtons', { static: false }) public headerButtons: TemplateRef<any>;
  @ContentChild('additionalActionButtons',
                { static: false }) public additionalActionButtons: TemplateRef<TableActionButtonComponent>;

  public onDestroy$: Subject<void>;

  public count: number;
  public pageSize: number;
  public pageSizeChange: EventEmitter<number>;
  public filterText: string;
  public expandedElements: SelectionModel<TableDataEntry>;

  private _tableConfig: TableConfig;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {
    this._tableConfig = {
      filter: true,
      filterSelects: [],
      columns: [],
      actionButtons: [
        TableConfigActionButton.EDIT,
        TableConfigActionButton.ACTIVE,
        TableConfigActionButton.DELETE,
      ],
    };
    this.dataSource = new MatTableDataSource([]);
    this.pageSize = 20;
    this.pageSizeChange = new EventEmitter();
    this.filterText = '';
    this.filterChanged = new EventEmitter();
    this.action = new EventEmitter();
    this.selection = new SelectionModel<string>(true, []);
    this.selectionChange = new EventEmitter<SelectionModel<string>>();
    this.onDestroy$ = new Subject();
  }

  public ngOnInit(): void {
    this.expandedElements = new SelectionModel(
      this.hasMultipleExpansions,
      this.hasMultipleExpansions ? [] : undefined,
    );
  }

  public ngAfterViewInit(): void {
    // If the user changes the sort order or applies a filter, reset back to the first page.
    merge(this.sort.sortChange, this.filterChanged)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.paginator.page.next(1));

    merge(this.sort.sortChange,
          this.paginator.page,
          this.pageSizeChange,
          this.filterChanged,
          this.action)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.getData({
            sort: this.sort.active,
            order: this.sort.direction,
            page: this.paginator.pageIndex,
            itemsPerPage: this.pageSize,
            filters: this.tableConfig.filterSelects.map((filter: TableConfigFilterSelect) => {
              return {
                field: filter.field,
                value: filter.value,
              };
            }).concat([
              {
                field: 'filterText',
                value: this.filterText,
              },
            ]),
          });
        }),
        takeUntil(this.onDestroy$),
        catchError((e: any) => {
          console.log(e);
          return of([]);
        }),
      ).subscribe((data: TableData) => {
        this.dataSource.data = data.data;
        this.count = data.count;
        this.cdr.detectChanges();
      });
    this.selectionChange.next(this.selection);
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  public setPageSize(pageSize: number): void {
    this.pageSize = pageSize;
    this.pageSizeChange.emit(pageSize);
  }

  public triggerAction(action: TableConfigActionButton, item: TableDataEntry): void {
    if (!item.isLoading) {
      this.action.emit([action, item]);
    }
  }

  public masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row.id));
    this.selectionChange.next(this.selection);
  }

  public isAllSelected(): boolean {
    const maxCount = this.dataSource.data.length;
    const selectedCount = this.dataSource.data.map(e => e.id).filter(id => this.selection.isSelected(id)).length;
    return maxCount === selectedCount && maxCount !== 0;
  }

}
