import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WeblibImageService {

  public baseUrl: string = 'https://d3ag-weblib.azureedge.net';

  public get svgLogoUrl(): string {
    return `${this.baseUrl}/logos/svg/`;
  }

  public get svgCompetitionUrl(): string {
    return `${this.baseUrl}/competitions/svg/`;
  }

  constructor() {
  }
}
