import { Component, OnInit } from '@angular/core';
import { StsAuthService } from 'sts-auth';

@Component({
  selector: 'dh-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss'],
})
export class AuthCallbackComponent implements OnInit {

  public spinnerName: string;

  constructor(private authService: StsAuthService) {
    this.spinnerName = 'callback-spinner';
  }

  public ngOnInit(): void {
    this.authService.handleAuthentication();
  }
}
