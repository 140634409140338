import { NgModule } from '@angular/core';
import { SharedLayoutModule } from '../../shared-layout.module';
import { SectionComponent } from './section.component';

@NgModule({
  declarations: [SectionComponent],
  imports: [SharedLayoutModule],
  exports: [SectionComponent],
})
export class SectionModule {
}
