<mat-form-field appearance="outline" [hideRequiredMarker]="true" subscriptSizing="dynamic">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    matInput
    matTooltip="{{ tooltip || '' }}"
    [formControl]="control"
    [type]="type"
    [maxlength]="maxlength"
    [required]="isRequired"
    [readonly]="isReadOnly"
  />
  <fa-icon
    matSuffix
    *ngIf="isPassword"
    class="custom-icon"
    (click)="onClickEye()"
    [icon]="hideText ? 'eye' : 'eye-slash'"
  ></fa-icon>
</mat-form-field>
<mat-error *ngIf="control?.hasError('message') && control?.invalid && control?.touched">
  {{ control?.getError('message') | translate }}
</mat-error>
